// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import Menu from "./Menu";
import Submenu from "./Submenu";
import ContextSubmenu from "./ContextSubmenu";
import Qna from "./auditoriums/QAndA";
import Poll from "./auditoriums/PublicPoll";
import Profile from "./Profile";
import { createHashHistory } from 'history'
import { FirebaseContext } from "../firebase";
import AppQna from "../component/App1";
// import PublicChat from "./publicChat/publicChat";
import { isMobileOnly, isTablet } from 'react-device-detect';
// import ChatLoader from "./ChatLoader";
import Dailyco from "./dailyco/dailyco";
import moment from "moment";
import swal from 'sweetalert';
import LandscapeMessage from './landscapeMessage';
import { componentFromStream } from "recompose";



export const history = createHashHistory()

const menuItems = [
    {
        id: 1,
        name: "Lobby",
        class: "Newicon-Lobby",//"icon-home",
        path: 3,
        isContextMenuTitle: true,
        contextMenuTitle: "Lobby",
        method: "mobileHome",
        level: 3,
        // subMenus: [
        //     { id: 2, name: "Tetris-Game", isHighlighted: false, level: 1, method: "mobileIframe", saveMenuState: false },
        //     { id: 3, name: "Photobooth", isHighlighted: false, level: 1, method: "mobileIframe", saveMenuState: false },
        // ],
    },
    {
        id: 2,
        name: "TownHall",
        class: "Newicon-Meeting",//"icon-video",
        path: 6,
        isContextMenuTitle: true,
        method: "mobileAuditorium",
        contextMenuTitle: "Auditoriums",
        level: 0,
        // subMenus: [
        //     {
        //         id: 1, name: "TownHall", isHighlighted: false, level: 1, method: "mobileAuditorium", saveMenuState: false
        //     },
        //     {
        //         id: 2, name: "BallRoom", isHighlighted: false, level: 1, method: "mobileAuditorium", saveMenuState: true, subMenus: [
        //             { id: 1, name: "Q&A", isHighlighted: false, level: 2, saveMenuState: false, visibleOnClick: true },
        //             { id: 2, name: "Public Poll", isHighlighted: false, level: 2, saveMenuState: false, visibleOnClick: true }
        //         ]
        //     }
        // ],
    },
    {
        id: 3,
        name: "BallRoom",
        class: "Newicon-Launch",//"icon-video",
        path: 4,
        isContextMenuTitle: true,
        contextMenuTitle: "BallRoom",
        method: "mobileAuditorium",
        level: 0,
        subMenus: [
            { id: 1, name: "Q&A", isHighlighted: false, level: 1, saveMenuState: false, visibleOnClick: true },
            { id: 2, name: "Public Poll", isHighlighted: false, level: 1, saveMenuState: false, visibleOnClick: true }
        ],
    },
    {
        id: 4,
        name: "Halls",
        class: "Newicon-Titans",//"icon-stall",
        path: 3,
        isContextMenuTitle: true,
        contextMenuTitle: "Halls",
        level: 3,
        method: "mobileHall",
        // subMenus: [
        //     {
        //         id: 1, name: "Azure", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 2, name: "Arian", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 3, name: "Avior", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 4, name: "Akuna", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 5, name: "Cronous", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 6, name: "Vega", isHighlighted: false, level: 1, method: "mobileHotspot", saveMenuState: false
        //     },
        //     {
        //         id: 7, name: "Coming Soon", isHighlighted: false, level: 1, saveMenuState: false
        //     },
        // ]
    },
    {
        id: 5,
        name: "user",
        class: "Newicon-Profile",//"icon-user",
        path: 4,
        level: 0
    },
];

const MenuStates =
{
    Tutorial: 0,
    Home: 1,
    Auditorium: 2,
    Halls: 3,
    Chat: 4,
    Profile: 5,
    none: 0
}

const tutorialStyle = {
    padding: "0rem 1.75rem",
    color: "white",
    marginTop: "2rem",
    lineHeight: "1.5rem",
    textAlign: "center"
};

class Home extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);
        this.state = {
            menuItems: menuItems,
            activeMenuLevel0: null,
            activeMenuLevel1: null,
            activeMenuLevel2: null,
            currentMenuState: MenuStates.Tutorial,
            lastActiveMenu: menuItems[0],
            isInteractable: false,
            inTransition: false,
            isMenuActive: true,
            showProfile: false,
            updateLiveParticipantVideoCall: false,
            showRoomOptions: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.hideMenuLevel1 = this.hideMenuLevel1.bind(this);
        this.handleSubmenuClick = this.handleSubmenuClick.bind(this);
        this.onHeadingClick = this.onHeadingClick.bind(this);
        this.handleSubContextMenuLevel1 = this.handleSubContextMenuLevel1.bind(this);
        this.handleSubContextMenuLevel2 = this.handleSubContextMenuLevel2.bind(this);
        this.onSwipeUpClicked = this.onSwipeUpClicked.bind(this);
        this.resetSubMenuActiveId = this.resetSubMenuActiveId.bind(this);
        this.listener = null;
        this.isMobileDevice = false;

        //to pass to daily co 
        this.LiveParticipantListFromFirebase = new Map();
        this.canStartVideoCall = false;
        //
        this.LiveParticipantAllRoomListFromFirebase = new Map();
        this.AllRoomCanStartVideoCall = new Map();

        window.ReactHomeManager = this; //Home manager reference for calling React Functions from Canvas
    }

    //#region  react LifeCycleFunction(componentDidMount// )
    componentDidMount() {
        try {
            if (!this.context.currentUser) {
                window.location.href = "/index.html";
            } else {
                this.addFirebaseBallRoomLisenter();
                this.context.homeVideoPrepareFunction = this.addTownHallLisenter;
                this.context.homeAllRoomVideoPrepareFunction = this.addAllRoomTownHallLisenter;
                // console.log("isMovile: ", isMobileOnly);
                // console.log("isTablet: ", isTablet);
                this.isMobileDevice = isMobileOnly;
                if (this.isMobileDevice) {

                    this.setState({
                        activeMenuLevel0: menuItems[0],
                        expended: false
                    });
                    window.startMobileCanvasApp();
                    this.addSwipeListeners();

                } else {
                    // console.log("found desktop");
                    window.startWindowCanvasApp();
                    this.ToggleUIInteractbleState(false);//change to false later
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    componentWillUnmount() {
        this.removeTownHallLisenter();
        this.removeFirebaseBallRoomLisenter();
        // console.log("unmount home.js");
    }

    //#endregion

    //#region SubMenu Header Click Function
    onHeadingClick(event, parentMenu) {
        // console.log("heading handler");
        event.preventDefault();
        if (parentMenu.level == 1) {

            this.handleContextMenuLevel1(parentMenu.parentMenu);
        } else if (parentMenu.level == 2) {
            this.handleContextMenuLevel2(parentMenu);
        }
    }
    handleContextMenuLevel1(menuItem) {
        // console.log("Handle context menu level 1");
        // console.log(menuItem);
        this.callReactConnectorFunction(menuItem);
        this.OpenMenu(menuItem);
    }
    handleContextMenuLevel2(menuItem) {
        // console.log("++++++++++++++++");
        // console.log("going back Handle context menu level 2");
        // console.log(menuItem);
        // console.log(menuItem.parentMenu);
        // console.log("++++++++++++++++");
        this.callReactConnectorFunction(menuItem.parentMenu);
        this.OpenMenu(menuItem.parentMenu);
        //should close the menu if mobile --- (Embed state change)
        if (menuItem.level === 2 && this.isMobileDevice) {
            if (menuItem.parentMenu.parentMenu.id === 2) {//from audi this is being called
                //
            }
        }
    }
    //#endregion

    //#region Level0 Menu Functions
    //called when Side bar buttons are clicked --Level0 Menu
    handleClick(event, menuItem) { //this function is called from Menu Component on getting button clicked
        event.preventDefault();
        console.log(menuItem);

        if(menuItem.id === 2 & this.context.moreThanOneRoom)
        {
            this.setState({
                showRoomOptions: true
            })
            return;
        }

        this.OpenMenu(menuItem);
        this.callReactConnectorFunction(menuItem);

    }
    //#endregion

    //#region  SubMenu Handler(all components call this function to run Menu)
    handleSubmenuClick(event, activeMenuLevel1) {
        event.preventDefault();
        if (activeMenuLevel1.level == 1) {
            this.handleSubContextMenuLevel1(activeMenuLevel1);
        } else if (activeMenuLevel1.level == 2) {
            this.handleSubContextMenuLevel2(activeMenuLevel1);
        }
        else if (activeMenuLevel1.level === 3) {
            this.handleSubContextMenuLevel3(activeMenuLevel1);
        }
    }
    //#endregion

    //#region Level 1 Menu Functions
    handleSubContextMenuLevel1(menuItem) {
        console.log("HandleSubcontextMenu Level1");
        console.log(menuItem);

        if (!menuItem.parentMenu)
            menuItem.parentMenu = this.state.activeMenuLevel0;

        if (menuItem.saveMenuState) {
            this.OpenMenuWithDifferentPreviousState(menuItem, menuItem, menuItem.parentMenu.id);
        } else {
            this.OpenMenuWithOutPreviousState(menuItem);
        }

        this.callReactConnectorFunction(menuItem);
    }
    //#endregion

    //#region Level 2 Menu Functions
    handleSubContextMenuLevel2(menuItem) {
        if (menuItem.level !== 2) {
            console.alert("menu item not of level 2");
        }
        // console.log("++++++++++++++++");
        // console.log("HandleSubcontextMenu Level2 ");
        // console.log(menuItem);
        // console.log("++++++++++++++++");

        menuItem.parentMenu = this.state.activeMenuLevel1;

        if (menuItem.saveMenuState) {
            this.OpenMenuWithDifferentPreviousState(menuItem, menuItem, menuItem.parentMenu.parentMenu.id)
        } else {

            if (menuItem.parentMenu.parentMenu.id !== 1)
                this.OpenMenuWithOutPreviousState(menuItem);
            else {
                // console.log("rendering info!!");
            }
        }

        if (menuItem.method) {
            window.canvasManager[menuItem.method](menuItem);
        }

        if (this.isMobileDevice) {
            if (menuItem.parentMenu.parentMenu.id === 2) {
                this.onSwipeUpClicked(true);
            }
        }
    }
    //#endregion

    //#region Level 3 Menu Functions
    handleSubContextMenuLevel3(menuItem) {
        // console.log("HandleSubcontextMenu Level3 ");
        menuItem.parentMenu = this.state.activeMenuLevel2;

        // this.OpenMenuWithOutPreviousState(menuItem);
        this.callReactConnectorFunction(menuItem)
    }
    //#endregion

    //#region UI Interaction
    //to toggle UI interactiability
    ToggleUIInteractbleState = (StateValue) => {
        this.setState({
            isInteractable: StateValue
        });
        //this state value will be passed as props for component to know if they can take action or not
        // console.log("UI is interaction stae: " + StateValue);
    }

    ToggleMenuLoading = (value) => {
        this.setState({
            inTransition: value,
            isInteractable: !value
        });
        //will be called from playcanvas when some video request will be sent    
        // console.log("loading togggled : " + value);
    }

    resetSubMenuActiveId() {
        this.setState({
            subMenuActiveId: -1
        });
    }

    setSubMenuActiveId(value) {
        // console.log(value);
        this.setState({
            subMenuActiveId: value
        });
    }

    ChangeState = (event, menuItem) => {
        // window.canvasManager.mobileHome();
        this.OpenMenu(menuItem);
        // this.setState({
        //     activeMenuLevel0: menuItem,
        //     activeMenuLevel1: null,
        //     activeMenuLevel2: null,
        //     activeMenuLevel3: null,
        //     lastActiveMenu: null,
        //     currentMenuState: menuItem.id
        // });
    }
    //#endregion

    //#region Open Menu Function
    OpenMenu = (menuItem) => {
        // console.log(menuItem);
        if (menuItem.id == 5) {
            if (this.state.showProfile) {
                this.hideProfileMenu();
                return;
            } else {
                this.setState({
                    showProfile: true,
                    currentMenuState: menuItem.id
                });
                // console.log("set satate to showprld");
                return;
            }
        }

        var lastMenu = this.getLastActiveMenu();
        let activeMenuLevelArray = new Array(4).fill(null);
        if (activeMenuLevelArray.length >= menuItem.level) {
            activeMenuLevelArray[menuItem.level] = menuItem;
            this.setState({
                activeMenuLevel0: activeMenuLevelArray[0],
                activeMenuLevel1: activeMenuLevelArray[1],
                activeMenuLevel2: activeMenuLevelArray[2],
                activeMenuLevel3: activeMenuLevelArray[3],
                lastActiveMenu: lastMenu,
                currentMenuState: menuItem.id,
                showProfile: false,
            });
        } else {
            console.log("Please check value you are passing to Open Menu");
        }
    }

    OpenMenuWithOutPreviousState = (menuItem) => {
        console.log(menuItem);

        let activeMenuLevelArray = new Array(4).fill(null);
        if (activeMenuLevelArray.length >= menuItem.level) {
            activeMenuLevelArray[menuItem.level] = menuItem;
            console.log(activeMenuLevelArray);
            this.setState({
                activeMenuLevel0: activeMenuLevelArray[0],
                activeMenuLevel1: activeMenuLevelArray[1],
                activeMenuLevel2: activeMenuLevelArray[2],
                activeMenuLevel3: activeMenuLevelArray[3],
                showProfile: false,

            });
        } else {
            console.log("Please check value you are passing to Open Menu");
        }
    }

    OpenMenuWithDifferentPreviousState = (menuItem, lastActiveMenu, currentMenuStateId) => {
        let activeMenuLevelArray = new Array(4).fill(null);
        if (activeMenuLevelArray.length >= menuItem.level) {
            activeMenuLevelArray[menuItem.level] = menuItem;
            this.setState({
                activeMenuLevel0: activeMenuLevelArray[0],
                activeMenuLevel1: activeMenuLevelArray[1],
                activeMenuLevel2: activeMenuLevelArray[2],
                activeMenuLevel3: activeMenuLevelArray[3],
                lastActiveMenu: lastActiveMenu,
                currentMenuState: currentMenuStateId,
                showProfile: false,
            });
        } else {
            console.log("Please check value you are passing to Open Menu");
        }
    }


    OpenPreviousSavedMenu = (menuItem) => {
        if (this.state.lastActiveMenu) {

            if (menuItem.id === this.state.currentMenuState) {

                switch (this.state.lastActiveMenu.level) {

                    case 1:
                        // if(lastActiveMenu.parentMenu.id === 1 && lastActiveMenu > 1)
                        this.setState({
                            activeMenuLevel0: null,
                            activeMenuLevel1: this.state.lastActiveMenu,
                            activeMenuLevel2: null,
                            activeMenuLevel3: null,
                            showProfile: false,
                        });
                        return;
                    case 2:
                        this.setState({
                            activeMenuLevel0: null,
                            activeMenuLevel1: this.state.lastActiveMenu,
                            activeMenuLevel2: null,
                            activeMenuLevel3: null,
                            showProfile: false,

                        });
                        return;
                    case 3:
                        this.setState({
                            activeMenuLevel0: null,
                            activeMenuLevel1: null,
                            activeMenuLevel2: null,
                            activeMenuLevel3: this.state.lastActiveMenu,
                            showProfile: false,
                        });
                        return;
                }
            }
        }
    }

    OpenMenuWithId(id) {
        if (id == null) {
            console.log("Please check value passed in OpenMenu With Id");
            return;
        } else {
            if (id >= 0) {
                this.OpenMenu(menuItems[id])
                // console.log("openMenuIWthId");
            }
        }
    }

    OpenLastActiveMenu() {

        this.OpenMenu(this.getLastActiveMenu());
    }
    //#endregion

    //#region Swipe Function(add swipe listeners// show & hide menu)
    onSwipeUpClicked(expended) {
        let value = !expended;
        this.setState({ expended: value });
    }

    addSwipeListeners() {
        var self = this;
        // document.addEventListener('swiped-up', function (e) {
        //     if (e.target.id === "application-canvas") {
        //         console.log("swipefound Up!!");
        //         self.onSwipeUpClicked(true);
        //     } // element that was swiped
        // });
        // document.addEventListener('swiped-down', function (e) {
        //     if (e.target.id === "application-canvas") {
        //         // console.log("swipefound Down!!");
        //         self.onSwipeUpClicked(false);//
        //     }
        // });

        document.addEventListener('swiped-right', function (e) {
            if (e.target.id === "application-canvas") {
                window.canvasManager.moveCameraLeft();
                // window.canvasManager.moveCameraRight();
                // console.log("swipefound Right!!");
            }
        });


        document.addEventListener('swiped-left', function (e) {
            if (e.target.id === "application-canvas") {
                // window.canvasManager.moveCameraLeft();
                window.canvasManager.moveCameraRight();
                // console.log("swipefound Left!!");
            }
        });
    }

    //#endregion

    //#region hideMenu Functions(hide lvl1, lvl2, lvl3 & subContextMenu)
    hideMenuLevel1() {
        this.setState({ activeMenuLevel0: null });
    }

    hideMenuLevel2() {
        this.setState({ activeMenuLevel0: null });
        this.setState({ activeMenuLevel1: null });
    }

    hideMenuLevel3() {

        // if (this.isMobileDevice) {
        //     this.setState({ expended: true });
        // } else {
        //     this.setState({ activeMenuLevel0: null });
        //     this.setState({ activeMenuLevel1: null });
        //     this.setState({ activeMenuLevel2: null });
        // }
    }

    hideSubContextMenu() {
        this.setState({ activeMenuLevel1: null });
        this.setState({ activeMenuLevel2: null });
    }

    hideProfileMenu() {
        if (this.state.showProfile) {
            const lastmenu = this.getLastActiveMenu();
            this.setState({
                showProfile: false,
                currentMenuState: lastmenu.id
            });
        }
    }
    //#endregion

    //#region PlayCanvas Functions
    callReactConnectorFunction(menuItem) {
        if (menuItem.method !== undefined) {
            window.canvasManager[menuItem.method](menuItem);
        }
    }
    //#endregion

    //#region  firebase function

    removeFirebaseBallRoomLisenter() {
        if (this.ballRoomLisenter) {
            this.ballRoomLisenter();
        }
    }

    addFirebaseBallRoomLisenter() {
        var self = this;
        const db = this.context.db;
        let firstTime = true;
        this.ballRoomLisenter = db.collection("BallRoomAdmin").doc("eventAction").onSnapshot(function (doc) {
            // console.log(doc);
            // console.log(doc.docChanges());
            const data = doc.data();
            if (data) {
                self.ballRoomData = data;
                const status = self.getRoomEventStatus(self.ballRoomData);

                if (self.ballRoomData.startEvent === true) {
                    if (firstTime) {
                    }
                    else {
                        // console.log("move them ");
                        if (status.status === "ongoing") {
                            if (window.canvasManager) {
                                window.canvasManager["moveToAuditoriumForcefully"](menuItems[2]);
                            }
                        }
                    }
                } else {
                }
                //check if event is already Ended
                if (self.ballRoomData.endEvent === true) {
                    if (firstTime) {
                    } else {
                        if (status.status === "end") {
                            if (window.canvasManager) {
                                window.canvasManager["moveToLobbyForcefully"]();
                            }
                        }
                    }
                } else {
                }
                firstTime = false;
            }

        })
    }

    removeTownHallLisenter = () => {
        if (this.townHallLisenter) {
            this.townHallLisenter();
        }
    }

    addTownHallLisenter = () => {
        var self = this;
        this.LiveParticipantListFromFirebase.clear();

        if (this.context.videoCallRoomName !== undefined && this.context.videoCallRoomName.length > 0)
            this.townHallLisenter = this.context.db
                .collection("dailyco")
                .doc(this.context.videoCallRoomName)
                .onSnapshot(function (doc) {
                    if (doc.data()) {
                        const started = doc.data().callStarted;
                        self.canStartVideoCall = started;
                        // console.log(self.canStartVideoCall);
                        if (self.canStartVideoCall) {
                            const users = doc.data().users;
                            users.forEach(user => {
                                let userInfo =
                                {
                                    userid: user.userid,
                                    imageUrl: user.imageUrl
                                }
                                self.LiveParticipantListFromFirebase.set(user.sessionId, userInfo);
                            });
                            // console.log(self.LiveParticipantListFromFirebase);
                        }

                        self.setState({
                            updateLiveParticipantVideoCall: true
                        });
                    }
                });
    }


    addAllRoomTownHallLisenter = () => {
        console.log("present in more than one room: ", this.context.moreThanOneRoom);
        var self = this;
        this.LiveParticipantAllRoomListFromFirebase.clear();
        this.AllRoomCanStartVideoCall.clear();
        const roomNames = this.context.videoRoooms;

        roomNames.forEach(roomName => {
            if (roomName.length > 0) {
                self.context.db
                .collection("dailyco")
                .doc(roomName)
                .onSnapshot(function (doc) {
                    if (doc.data()) {
                        const started = doc.data().callStarted;
                        const roomNameDoc  = doc.data().roomName;
                        self.AllRoomCanStartVideoCall.set(roomName, started);

                        if(roomNameDoc === self.context.videoCallRoomName)
                        {
                            self.canStartVideoCall = started;
                        }
                        if (started && roomNameDoc === self.context.videoCallRoomName ) {
                            //should try to clear list here
                            const users = doc.data().users;
                            users.forEach(user => {
                                let userInfo =
                                {
                                    userid: user.userid,
                                    imageUrl: user.imageUrl
                                }
                                self.LiveParticipantListFromFirebase.set(user.sessionId, userInfo);
                            });
                            // console.log(self.LiveParticipantListFromFirebase);
                            
                            console.log("inside selected roomName Getting firebase list");

                        }

                        self.setState({
                            updateLiveParticipantVideoCall: true
                        });

                        console.log(roomNameDoc, self.AllRoomCanStartVideoCall,self.context.videoCallRoomName );
                    }
                });
            }
        });
    }
    //#endregion

    //#region utility funtions
    getRoomEventStatus(RoomData) {
        var startTime = moment(RoomData.startTime);
        var currentTime = moment().utcOffset("+05:30").format();
        var duration = startTime.diff(currentTime);
        var TimeLeft = moment.duration(duration);
        // console.log(TimeLeft)
        // console.log(TimeLeft.asMilliseconds())
        if (TimeLeft.asMilliseconds() > 0) {
            // console.log("Event will start in ", TimeLeft.humanize() );
            // console.log("all ready Started");
            return { status: "waiting", timeLeft: TimeLeft.humanize() };
            //have to send some value to calculate if we passed the event
        } else {
            if (RoomData.startEvent) {
                // console.log("all ready Started");
                return { status: "ongoing", videoUrl: RoomData.videoUrl };
            }
            else if (RoomData.endEvent) {
                // console.log("event ended");
                return { status: "end" };
            } else {
                // console.log("event aboutToStart");
                return { status: "aboutToStart" };
            }
        }
    }

    getBallRoomEventStatus() {
        console.log("getBallRoomEventStatus", this.ballRoomData);
        if (this.ballRoomData) {
            return this.getRoomEventStatus(this.ballRoomData);
        } else {
            return false;
        }
    }

    getTownHallStatus() {
        if (this.context.isAllowedTownHall) {

            if (this.context.videoRoomAdmin) {
                return { status: "CanEnter" };
            } else {
                if (this.canStartVideoCall) {
                    return { status: "CanEnter" };

                } else {
                    return { status: "NoEntry", message: "Video Conference is not active right now." }
                }
            }



        } else {
            return { status: "notAllowed", message: "Sorry, You Can not enter this hall." };
        }
    }

    showInfoPopUp(message) {
        console.log("showInfoPopUp", message);
        swal({
            title: message, // `Event will start at ${roomStatus.timeLeft}`,
            icon: "info",
            className: "video-swal-modal",
            button: "continue",
        });
    }

    getLastActiveMenu() {
        var lastMenu = null;
        if (this.state.activeMenuLevel0 !== null) {
            lastMenu = this.state.activeMenuLevel0;
        }
        else if (this.state.activeMenuLevel1 !== null) {
            lastMenu = this.state.activeMenuLevel1;
        }
        else if (this.state.activeMenuLevel2 !== null) {
            lastMenu = this.state.activeMenuLevel2;
        }
        else if (this.state.activeMenuLevel3 !== null) {
            lastMenu = this.state.activeMenuLevel3;
        }
        else {
            lastMenu = this.state.lastActiveMenu;
        }

        return lastMenu;
    }
    //#endregion


    //#region Video Room Function MEnu
    showRoomOptions()
    {
        this.setState({
            showRoomOptions: true
        });
    }

    checkIfRoomOptionAreVisible()
    {
        return this.state.showRoomOptions;
    }

    hideRoomOptions()
    {
        if(this.state.showRoomOptions)
        {
            this.onRoomOptionCancel();
        }
    }

    renderRoomOptions()
    {
        const roomNames = this.context.videoRoooms;
        if(roomNames.length  > 0)
        {
            var optionMenu = [];
            roomNames.forEach( (room, key )=> {
                optionMenu.push(
                <button key={key}  className="roomOption" onClick={ (event) => this.onRoomOptionSelect(event, room)} >{room}</button>
                )
            });
            return optionMenu;
        }
    }

    onRoomOptionSelect = (event, roomName) =>
    {
        this.context.videoCallRoomName = roomName;
        this.canStartVideoCall = this.AllRoomCanStartVideoCall.get(roomName);
        
        this.OpenMenu(menuItems[1]);
        this.callReactConnectorFunction(menuItems[1]);
        this.setState({
            showRoomOptions: false,
            updateLiveParticipantVideoCall: true,
        });
        console.log(this.context.videoCallRoomName, this.canStartVideoCall );
    }

    onRoomOptionCancel = (event) =>
    {
        this.setState({
            showRoomOptions: false,
            updateLiveParticipantVideoCall: true,
        });
    }
    //#endregion

    checkValue()
    {
        console.log(this.state.activeMenuLevel0);
    }
    render() {
        const { expended } = this.state;

        return (
            <>
                {
                    this.isMobileDevice ?
                        (
                            <LandscapeMessage
                                activeMenu={this.state.activeMenuLevel0}
                            >
                            </LandscapeMessage>
                        )
                        : (null)
                }
                <header className="headerBox d-none">
                    {/* <img
                        src="assets/images/logos/logo.png"
                        className="headerBox__logo"
                        alt=""
                    /> */}
                </header>

                <section onClick={event => this.hideProfileMenu(event)} className="contentCntr">
                    <article className={`img-bg videoBox h-100 ${
                        (this.isMobileDevice) ?
                            ((this.state.activeMenuLevel0 && (this.state.activeMenuLevel0.id === 2 || this.state.activeMenuLevel0.id === 3)) ? "menu-active" : "") : (null)
                        }`} id="play">
                    </article>
                    {this.state.showRoomOptions ? (
                        <>
                            <div className="roomOptionBaseParent popover-modal" >
                                <div className="roomOptionBase">
                                    <div className="roomOptionTextArea">Please select the room<br></br> you want to enter</div>
                                    <div className="roomOptionButtonArea">
                                        {this.renderRoomOptions()}
                                    </div>
                                    <div className="roomOptionCancelArea">
                                        <button className="roomOptionCancelButton" onClick={(event)=> this.onRoomOptionCancel(event)} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ): (null)}
                </section>


                <footer className="footerBox">
                    <Menu items={this.state.menuItems} mainMenuState={this.state.currentMenuState} onMenuItemClick={this.handleClick} canInteract={this.state.isInteractable}></Menu>
                    {/* {
                        (!this.state.activeMenuLevel0 && !this.state.activeMenuLevel1 && !this.state.activeMenuLevel2 && !this.state.activeMenuLevel3) ?
                            (<>
                                <div className={`submenu-container active ${expended ? "" : "expended"}`}>
                                    <a className="footer__logo show-on-desktop">
                                        <img src="assets/images/logos/logo.png" alt="" />
                                    </a>
                                    <div className={`second-level-nav`}>
                                        <span className="swipe-up-btn hide-on-desktop"></span>
                                        <h3 className={`second-level-nav__title`}>Tutorial</h3>
                                        <div className="tutorial-section" style={tutorialStyle}>
                                            Please Go Through The Tutorial To Learn About Platform<br></br>
                                            <br></br>
                                            Explore And Experience The Best Virtual Event Ever!!
                                        </div>
                                    </div>

                                </div>
                            </>)
                            : (null)
                    } */}

                    {this.state.activeMenuLevel0 ?

                        (
                            <>
                                {this.state.activeMenuLevel0.subMenus ?
                                    (<>
                                        {/* <div className={`submenu-container active ${expended ? "" : "expended"}`}> */}
                                        {/* <a className="footer__logo show-on-desktop">
                                                <img src="assets/images/logos/logo.png" alt="" />
                                            </a> */}

                                        {this.state.activeMenuLevel0.id != 5 && this.state.menuItems.map((item) => (
                                            item.subMenus &&

                                            <Submenu key={item.id} item={item} subMenus={item.subMenus}
                                                activeMenuItem={this.state.activeMenuLevel0}
                                                onSubMenuItemClick={this.handleSubmenuClick}
                                                onHeadingClick={this.onHeadingClick}
                                                onSwipeUpClicked={this.onSwipeUpClicked}
                                                canInteract={this.state.isInteractable}
                                                inTransition={this.state.inTransition}
                                                externalActiveItemId={this.state.subMenuActiveId}
                                                resetExternalActiveItemId={this.resetSubMenuActiveId}
                                            ></Submenu>
                                        ))}
                                        {/* </div> */}
                                    </>) : (
                                        <>
                                            {this.state.activeMenuLevel0 && this.state.activeMenuLevel0.id == 5 &&
                                                <div className={`submenu-container active ${expended ? "" : "expended"} ${this.state.activeMenuLevel0.id == 5 ? "submenu-container--profile" : ""}`}>
                                                    {/* <a className="footer__logo show-on-desktop">
                                                        <img src="assets/images/logos/logo.png" alt="" />
                                                    </a> */}
                                                    <Profile></Profile>
                                                </div>
                                            }
                                        </>
                                    )}
                            </>
                        )
                        : null}

                    {this.state.showProfile ? (
                        <>
                            <div className={`submenu-container active ${expended ? "" : "expended"} submenu-container--profile`}>
                                <Profile></Profile>
                            </div>
                        </>
                    ) : (null)}

                    {
                        (this.state.activeMenuLevel0 && (this.state.activeMenuLevel0.id == 2)) ?
                            (
                                <Dailyco
                                    menuItem={menuItems[0]}
                                    goToLobby={this.handleClick}
                                    name={this.context.currentUser.displayName}
                                    roomName={this.context.videoCallRoomName}
                                    isAdmin={this.context.videoRoomAdmin}
                                    LiveParticipantListFromFirebase={this.LiveParticipantListFromFirebase}
                                    videoCallStillOn={this.canStartVideoCall}>
                                </Dailyco>
                            )
                            : (null)
                    }

                    {this.state.activeMenuLevel1 && this.state.activeMenuLevel1.subMenus ? (
                        <div className={`submenu-container active  ${expended ? "" : "expended"}`}>
                            {/* <a className="footer__logo show-on-desktop">
                                <img src="assets/images/logos/logo.png" alt="" />
                            </a> */}
                            <ContextSubmenu
                                menuItems={this.state.activeMenuLevel1.subMenus}
                                parentMenuItem={this.state.activeMenuLevel1}
                                onMenuItemClick={this.handleSubmenuClick}
                                onHeadingClick={this.onHeadingClick}
                                canInteract={this.state.isInteractable}
                                inTransition={this.state.inTransition}
                                externalActiveItemId={this.state.subMenuActiveId}
                                resetExternalActiveItemId={this.resetSubMenuActiveId}
                            >
                            </ContextSubmenu>
                            )
                        </div>) : null}

                    {this.state.activeMenuLevel2 && this.state.activeMenuLevel2.subMenus ? (
                        <div className={`submenu-container active ${expended ? "" : "expended"}`}>
                            {/* <a className="footer__logo show-on-desktop">
                                <img src="assets/images/logos/logo.png" alt="" />
                            </a> */}
                            <ContextSubmenu
                                menuItems={this.state.activeMenuLevel2.subMenus}
                                parentMenuItem={this.state.activeMenuLevel2}
                                onMenuItemClick={this.handleSubmenuClick}
                                onHeadingClick={this.onHeadingClick}
                                canInteract={this.state.isInteractable}
                                inTransition={this.state.inTransition}
                                resetExternalActiveItemId={this.resetSubMenuActiveId}
                            >
                            </ContextSubmenu>
                            )
                        </div>) : null}

                            {/* {this.checkValue()} */}
                    { this.state.activeMenuLevel1 && this.state.activeMenuLevel1.parentMenu ? (
                        this.state.activeMenuLevel1.parentMenu.id === 3 && this.state.activeMenuLevel1.id === 1 ? (
                            <div className={`submenu-container active ${expended ? "" : "expended"}`}>
                                <a className="footer__logo show-on-desktop">
                                    <img src="assets/images/logos/logo.png" alt="" />
                                </a>
                                <AppQna item={this.state.activeMenuLevel1}
                                    onHeadingClick={this.onHeadingClick}></AppQna>
                            </div>
                        ) : (null)) : (null) }

                    {this.state.activeMenuLevel1 && this.state.activeMenuLevel1.parentMenu ? (
                        this.state.activeMenuLevel1.parentMenu.id === 3 && this.state.activeMenuLevel1.id === 2 ? (
                            <div className={`submenu-container active ${expended ? "" : "expended"}`}>
                                <a className="footer__logo show-on-desktop">
                                    <img src="assets/images/logos/logo.png" alt="" />
                                </a>
                                <Poll item={this.state.activeMenuLevel1}
                                    onHeadingClick={this.onHeadingClick} ></Poll>
                            </div>
                        ) : null
                    ) : null}

                </footer>
            </>
        );
    }
}

export default Home;
