import React, { Component } from "react";
import { FirebaseContext } from "../firebase/index";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
  alreaylogged: false
};

const buttonStyle=
{
  margin: "2% 0"
};

class Login extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.referer = "/home";
    // this.onlineStatus = this.props.location.state.online
    window.loginManager = this;
  }

  componentDidMount()
  {
    // var self = this;
    // setTimeout(function(){
    //   if(self.context.alreadyLoggedIn)
    //   self.setState({
    //     alreaylogged: true
    //   })
    // }, 500);
    // console.log("Mount!!", this.state, this.context.alreadyLoggedIn);
  }
  
  componentWillUnmount()
  {
    // console.log("UnMount!!");
  }

  componentDidUpdate()
  {
    // console.log("update!!");
  }

  componentWillReceiveProps(newprops)
  {
    // console.log("new props comming");
    // console.log(newprops);
  }


  login(event) {
    event.preventDefault();
    const { email, password } = this.state;
    console.log(email, password);
    this.context
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        // this.props.history.push(this.referer);
      })
      .catch((err) => {
        // console.log(err);
        var error = "";
        switch(err.code)
        {
          case "auth/wrong-password":
            error = "Invalid username and password.";
            break;
          case "auth/user-not-found":
            error = "User not found";
            break;
          case "auth/too-many-requests":
            error= "Too many invalid requests, please wait for 60 seconds before retrying";
            break;
        }
        this.setState({ error });
      });
  }

  handleChange(e, name) {
    this.handleStateChange([name], e.target.value);
  }

  handleStateChange(name, value) {
    this.setState({ [name]: value });
  }

  checkVallue()
  {
    // console.log(this.context.alreadyLoggedIn);
  }

  render() {
    return (
      <>
      {this.checkVallue()}
        <section className="loginBox">
          <div className="loginBox__logo default-padding pd-t35 pd-b20">
            <img src="/assets/images/logos/logo-icon.png" alt="" />
          </div>
          <div className="scrollable-part">
            <form onSubmit={this.login}>
              <div className="default-padding pd-t35 pd-b30">
                <h2 className="bold-text light-color font-17 mg-b25">
                  Visitor’s Log in
                </h2>
                <div className="form-group">
                  <input
                    id="email"
                    className="form-control"
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => this.handleChange(e, "email")}
                    value={this.state.email}
                    required
                  />

                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter Phone Number"
                    id="password"
                    onChange={(e) => this.handleChange(e, "password")}
                    value={this.state.password}
                    required
                  />

                  {this.state.error ? (
                    <span className="error">{this.state.error}</span>
                  ) : null}

                  {(this.context.alreadyLoggedIn) && !this.state.error  ? (
                    <span className="error">User is already logged in from another system.</span>
                  ) : null}

                </div>

              <button className="btn btn-primary" style={buttonStyle} >Log In</button>
              </div>
            </form>
          </div>
        </section>
        <section className="contentCntr">
          <article
            className="videoBox img-bg menu-active"
            style={{
              "backgroundImage": "url('assets/images/canvas-img_2.jpg')",
            }}
          >
            <button className="fast-forward-btn"></button>
            <button className="fast-backward-btn"></button>
          </article>
        </section>
      </>
    );
  }
}

export default Login;
